import React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { shape, string } from "prop-types";
import Phone from "./items/phone";
import PostalAdress from "./items/postalAdress";
import HowCome from "./items/howCome";
import Instagram from "./items/instagram";
import Facebook from "./items/facebook";
import Linkedin from "./items/linkedin";

const config = require("../../config.json")
const compagnyName = config.APP_COMPAGNY_NAME;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;
const colorBackgroundTable = config.APP_COLORBACKGROUNDTABLE;
const widthImage = config.APP_WIDTHIMAGE;
const heightImage = config.APP_HEIGHTIMAGE;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(35vh);
  line-height: 14px;
  @media (max-width: 1280px) {
    transform: translateY(0vh);
  }
`;
const ContainerSocial = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  margin: "10px 0px 0px 0px",
};
const ContainerPartner = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  marginBottom: "10px"
};
const ContainerPartnerPlayground = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  marginTop: "13px",
};
const SignatureStyle = {
  backgroundColor: colorBackgroundTable,
};
const ImageWrapper = {
  position: "relative",
  width: widthImage,
  height: heightImage,
  objectFit: "cover",
};
const Image = {
  width: widthImage,
  height: heightImage,
  display: "block",
  marginRight: "10px"
};
const ImageNewsletter = {
  width: "400px",
  height: "154px",
}
const ImagePlayground = {
  display: "block",
  width: "369px",
  height: "119px",
  marginTop: "17px",
}
const Logo = {
  width: "auto",
  height: "auto",
}
const TextWrapper = {
  marginLeft: "20px",
  fontFamily: "Arial",
  verticalAlign: "top"
};
const Name = {
  fontSize: "14px",
  margin: "0",
  fontWeight: "700",
  lineHeight: "14px",
  display: "block",
};
const Job = {
  display: "block",
  fontSize: "12px",
  color: "#6d7989",
  fontWeight: "700",
  fontStyle: "italic",
  marginBottom: "10px"
};
const Link = {
  color: compagnyColorPrimary,
  fontSize: "12px",
  textDecoration: 'none'
};
const WebsiteUrl = {
  margin: "0px 0px 15px 0",
  lineHeight: "12px"
};

function constructImgUrl(name, ext) {
  if (ext) {
    return `https://files.myclientisrich.com/signatures_mcir/v2/${name}.${ext}`;
  }
}

const SignatureFormatter = ({ user, message, file, typeImage }) => {
  let urlUploadsImg = "";
  if (user.urlImage === "" || user.urlImage === null) {
    if (typeImage === "static") {
      urlUploadsImg = constructImgUrl(user.imgName, "png");
    }
    else {
      urlUploadsImg = constructImgUrl(user.imgName, "gif");
    }
  }

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {compagnyName} | Signature
        </title>
      </Helmet>
      <div className='wrapper-table'>
        <table className="signature" style={SignatureStyle}>
          <tbody>
            <tr>
              <td className="image__wrapper" style={ImageWrapper}>
                <a href={`https://${user.urlImage}`} target='_blank' rel="noreferrer">
                  <img src={urlUploadsImg} style={Image} alt="logo" />
                </a>
              </td>
              <td className="text__wrapper" style={TextWrapper}>
                <span style={Object.assign({}, Name, { color: compagnyColorPrimary })}>
                  {user.firstname} {user.lastname}
                </span>
                <span style={Job}>{user.job}</span>
                {user.phone ? (
                  <Phone phone={user.phone} />
                ) : null}
                 {user.mobile ? (
                  <Phone phone={user.mobile} />
                ) : null}
                <p style={WebsiteUrl}>
                  <a href={`https://www.${user.website}`} target="_blank" rel="noreferrer" style={Link}>{user.website}</a>
                </p>
                <HowCome />
                {user.postalAdress ? (
                  <PostalAdress adress={user.postalAdress} />
                ) : null}
                <p style={ContainerSocial}>
                  {user.facebook ? (
                    <Facebook facebook={user.facebook} />
                  ) : null}
                  {user.linkedin ? (
                    <Linkedin linkedin={user.linkedin} />
                  ) : null}
                  {user.instagram ? (
                    <Instagram instagram={user.instagram} />
                  ) : null}
                </p>
              </td>
            </tr>
            <tr>
              <td bgcolor="white" colSpan={2}>
                { user.playground  ? (
                  <a target="_blank" href="https://www.myclientisrich.com/playground#next-playground" rel="noreferrer" >
                    <img style={ImagePlayground} src={`https://files.myclientisrich.com/signatures_mcir/v3/playground.png`} alt="logo" />
                  </a>
                ):(
                  <a target="_blank" href="https://www.myclientisrich.com/newsletter" rel="noreferrer" >
                  <img style={ImageNewsletter} src={`https://files.myclientisrich.com/signatures_mcir/v2/icons/newsletter.png`} alt="logo" />
                </a>
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
              { user.playground  ? (
                <div style={ContainerPartnerPlayground}>
                  <a href="https://www.myclientisrich.com/nos-metiers/formations" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/qualiopi-nb.png`} alt="logo" />
                  </a>
                  <a href="https://www.asa-basket.fr/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/asa-nb.png`} alt="logo" />
                  </a>
                  <a href="https://sigstrasbourg.fr/actionnaires" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/sig-nb.png`} alt="logo" />
                  </a>
                  <a href="https://www.apm.fr/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/apm-nb.png`} alt="logo" />
                  </a>
                  <a href="https://www.excellence.alsace/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/alsace-exellence-nb.png`} alt="logo" />
                  </a>
                  <a href="https://www.moovjee.fr/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/moovjee-nb.png`} alt="logo" />
                  </a>

                 <a href="https://www.myclientisrich.com/playground/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/playground-nb.png`} alt="logo" />
                  </a>
                  <a href="https://www.cjd.net/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/cjd-nb.png`} alt="logo" />
                  </a>
                  <a href="https://www.initiativesdurables.com/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/id-nb.png`} alt="logo" />
                  </a>
                  <a href="https://www.reseau-entreprendre.org/alsace/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/reseau-entreprendre-nb.png`} alt="logo" />
                  </a>
                </div>
                ):(
                  <>
                  <div style={ContainerPartner}>
                    <a href="https://www.myclientisrich.com/nos-metiers/formations" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/qualiopi.jpg`} alt="logo" />
                  </a>
                  <a href="https://www.asa-basket.fr/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/asa.jpg`} alt="logo" />
                  </a>
                  <a href="https://sigstrasbourg.fr/actionnaires" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/sig.jpg`} alt="logo" />
                  </a>
                  <a href="https://www.apm.fr/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/apm.jpg`} alt="logo" />
                  </a>
                  <a href="https://www.excellence.alsace/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/alsace-excellence.jpg`} alt="logo" />
                  </a>
                  <a href="https://www.moovjee.fr/" target="_blank" rel="noreferrer">
                    <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/moovjee.jpg`} alt="logo" />
                  </a>
                  </div>
                  <div style={ContainerPartner}>
                  <a href="https://www.myclientisrich.com/playground/" target="_blank" rel="noreferrer">
                     <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/playground.jpg`} alt="logo" />
                   </a>
                   <a href="https://www.cjd.net/" target="_blank" rel="noreferrer">
                     <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/cjd.jpg`} alt="logo" />
                   </a>
                   <a href="https://www.initiativesdurables.com/" target="_blank" rel="noreferrer">
                     <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/id.jpg`} alt="logo" />
                   </a>
                   <a href="https://www.reseau-entreprendre.org/alsace/" target="_blank" rel="noreferrer">
                     <img style={Logo} src={`https://files.myclientisrich.com/signatures_mcir/v3/logos/reseau-entreprendre.jpg`} alt="logo" />
                   </a>
                 </div>
                 </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
};

SignatureFormatter.propTypes = {
  user: shape({
    name: string,
    job: string,
    availability: string,
    phone: string,
    imgName: string
  }).isRequired
};

export default SignatureFormatter;
